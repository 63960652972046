import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'transformString',
	standalone: true,
})
export class TransformStringPipe implements PipeTransform {
	/**
	 * Transforms a string by removing a specific character and replacing it with another character.
	 * The default character to replace with is '-'.
	 *
	 * @param value - The original string to transform.
	 * @param remove - The value to remove from the original string.
	 * @param replaceWith - The value to replace the removed value. Defaults to '-'.
	 * @returns The transformed string with the first letter uppercase and the rest lowercase.
	 */
	transform(value: string, remove: string, replaceWith: string = '-'): string {
		if (!value) return value;

		const transformedValue = value.split(remove).join(replaceWith);

		return (
			transformedValue.charAt(0).toUpperCase() +
			transformedValue.slice(1).toLowerCase()
		);
	}
}
