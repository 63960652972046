import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, StateKey, TransferState } from '@angular/core';
import { Observable, tap, of } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class TransferStateService {
	private isServer = false;

	constructor(
		private tstate: TransferState,
		@Inject(PLATFORM_ID) platformId: object,
	) {
		this.isServer = isPlatformServer(platformId);
	}

	getData<T>(
		key: StateKey<T>,
		getDataObservable: Observable<T>,
		defaultValue: any = [],
	): Observable<T> {
		if (this.tstate.hasKey(key)) {
			const observable$ = of(this.tstate.get(key, defaultValue));
			this.tstate.remove(key);
			return observable$;
		} else {
			return getDataObservable.pipe(
				tap((data) => {
					if (this.isServer) {
						this.tstate.set<T>(key, data);
					}
				}),
			);
		}
	}

	isServerInit<T>(key: StateKey<T>) {
		return this.tstate.hasKey(key);
	}
}
