import { Pipe, PipeTransform } from '@angular/core';
type FullName = string;

@Pipe({
	standalone: true,
	name: 'lastNameInitial',
})
export class LastNameInitialPipe implements PipeTransform {
	transform(fullName: FullName): string {
		const [firstname, lastname] = fullName.split(' ');

		if (firstname && lastname) {
			return `${firstname} ${lastname.charAt(0)}`;
		} else if (firstname) {
			return firstname;
		}

		return '';
	}
}
