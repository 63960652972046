import { Pipe, PipeTransform } from '@angular/core';
import { OrdinalPipe } from './ordinal.pipe';

@Pipe({
	standalone: true,
	name: 'ucdate',
})
export class UcDatePipe implements PipeTransform {
	private _ordinal = new OrdinalPipe();

	transform(stringDate: string) {
		if (!stringDate) return;

		const months = [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sep',
			'Oct',
			'Nov',
			'Dec',
		];

		const convertedDate = new Date(stringDate.replace(/\s/, 'T'));
		const month = convertedDate.getMonth();
		const year = convertedDate.getFullYear();
		const day = convertedDate.getDate();
		const monthStr = months[month];
		const dayString = this._ordinal.transform(day);

		return `${monthStr} ${dayString} ${year}`;
	}
}
