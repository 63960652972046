import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class LocalStorageService {
	constructor(@Inject(PLATFORM_ID) private _platformId: object) {}

	setItem(key: string, value: any) {
		if (isPlatformServer(this._platformId)) {
			return;
		}
		localStorage.setItem(key, value);
	}

	getItem(key: string) {
		if (isPlatformServer(this._platformId)) {
			return;
		}
		return localStorage.getItem(key);
	}

	removeItem(key: string) {
		if (isPlatformServer(this._platformId)) {
			return;
		}
		localStorage.removeItem(key);
	}

	clear() {
		if (isPlatformServer(this._platformId)) {
			return;
		}
		localStorage.clear();
	}
}
