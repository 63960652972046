import { Pipe, PipeTransform } from '@angular/core';
import { Product, UppercaseProduct } from '@uc/web/shared/data-models';

@Pipe({
	name: 'productToLowercase',
	standalone: true,
})
export class ProductToLowercasePipe implements PipeTransform {
	transform(product: UppercaseProduct): Product {
		if (!product) return product;

		return product.toLowerCase() as Product;
	}
}
