import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	standalone: true,
	name: 'cdn',
})
export class CdnPathPipe implements PipeTransform {
	private _cdnPath = 'https://cdn.universitycompare.com';

	transform(path: string | null = ''): string {
		if (!path) return '';

		//path is absolute, don't do anyhting
		if (path.includes('universitycompare.com') || path.includes('clearing.co.uk')) {
			return path;
		}

		return path[0] !== '/' ? `${this._cdnPath}/${path}` : `${this._cdnPath}${path}`;
	}
}
