import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class PaginationService {
	private readonly numberOfUnis = 20;
	private readonly rootPath = 'https://universitycompare.com';
	private headTag = this.doc.head;

	constructor(
		@Inject(DOCUMENT) private doc: Document,
		private location: Location,
	) {}

	remove() {
		this.removeElement('userUrl');
		this.removeElement('userUrlPrev');
		this.removeElement('userUrlNext');
	}

	createPaginationSEO(pageNumber: number, countTotalUnis: number) {
		const path = this.location.path();
		const url = this.rootPath + path;

		let prevPage = null;
		let nextPage = null;

		const margin = pageNumber * this.numberOfUnis;

		if (margin >= countTotalUnis && pageNumber === 1) {
			//load more not avail on first page - only add link for current
			this.removeElement('userUrlPrev');
			this.appendElement('canonical', 'userUrl', url);
			this.removeElement('userUrlNext');
		} else if (margin >= countTotalUnis && pageNumber !== 1) {
			//load more not avail, but not first page.
			this.appendElement('canonical', 'userUrl', url);
			this.removeElement('userUrlNext');
			// If the results are less than pageNumber * numberOfUnis
			if (this.findPageNumber(countTotalUnis) >= 1) {
				this.appendElement(
					'prev',
					'userUrlPrev',
					this.buildUrl(url, pageNumber, this.findPageNumber(countTotalUnis)),
				);
			} else {
				this.removeElement('userUrlPrev');
			}
		} else if (pageNumber === 1) {
			// on first page & can load more
			nextPage = 2;
			this.removeElement('userUrlPrev');
			this.appendElement('canonical', 'userUrl', url);
			this.appendElement(
				'next',
				'userUrlNext',
				this.buildUrl(url, pageNumber, nextPage),
			);
		} else {
			prevPage = pageNumber - 1;
			nextPage = pageNumber + 1;
			this.appendElement(
				'prev',
				'userUrlPrev',
				this.buildUrl(url, pageNumber, prevPage),
			);
			this.appendElement('canonical', 'userUrl', url);
			this.appendElement(
				'next',
				'userUrlNext',
				this.buildUrl(url, pageNumber, nextPage),
			);
		}
	}

	private appendElement(rel: string, id: string, url: string): void {
		const element = this.checkLinkPresent(rel);
		element
			? this.updateLinkElement(element, url)
			: this.createLinkElement(rel, id, url);
	}

	private removeElement(id: string): void {
		const element = this.doc.getElementById(id) as HTMLLinkElement;
		if (element) {
			element.remove();
		}
	}

	private checkLinkPresent(rel: string): HTMLLinkElement | false {
		const element = this.doc.querySelector(`link[rel=${rel}]`) as HTMLLinkElement;
		return element ? element : false;
	}

	private createLinkElement(rel: string, id: string, url: string) {
		const link: HTMLLinkElement = this.doc.createElement('link');
		link.setAttribute('rel', rel);
		link.setAttribute('id', id);
		link.setAttribute('href', url);
		this.headTag.appendChild(link);
	}

	private updateLinkElement(element: HTMLLinkElement, url: string) {
		element.href = url;
	}

	private findPageNumber(totalUnis: number): number {
		return Math.floor(totalUnis / this.numberOfUnis);
	}

	private buildUrl(url: string, currentPage: number, updatePage: number) {
		url.replace('page=1', '').replace('offset=1', '');
		if (!url.includes('?page=') && !url.includes('&page=')) {
			if (url.includes('/?')) {
				return this._normaliseQueryParams(`${url}&page=${updatePage}`);
			}
			return this._normaliseQueryParams(`${url}?page=${updatePage}`);
		}
		return this._normaliseQueryParams(
			url.replace(`page=${currentPage}`, `page=${updatePage}`),
		);
	}

	private _normaliseQueryParams(url: string) {
		if (url?.includes('page=1')) {
			let newUrl = url.replace('page=1', '').replace('offset=1', '');
			if (newUrl.endsWith('&')) newUrl = newUrl.replace(/\&$/, '');
			if (newUrl.endsWith('?')) newUrl = newUrl.replace(/\?$/, '');
			if (newUrl.endsWith('?&')) newUrl = newUrl.replace(/\?&$/, '');
			return newUrl;
		}
		return url;
	}
}
