import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	standalone: true,
	name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
	transform(value: string, args: any[], stripHtml = false): string {
		if (!value) return '';

		const text = stripHtml ? String(value).replace(/<[^>]+>/gm, '') : value;
		const limit = args.length > 0 ? parseInt(args[0], 10) : 100;
		const trail = args.length > 1 ? args[1] : '...';
		return text.length > limit ? text.substring(0, limit - 1) + trail : text;
	}
}
