export function capitalise(str: string) {
	return str
		? str
				.split(' ')
				.map((word) => capitaliseFirstLetter(word))
				.join(' ')
		: '';
}

export function capitaliseFirstLetter(str: string) {
	return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
}

export function replaceDashWithSpace(str: string) {
	return str ? str.replace(/-/g, ' ') : '';
}
