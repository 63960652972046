import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	standalone: true,
	name: 'categoryLink',
})
export class CategoryLinkPipe implements PipeTransform {
	formatAdvice(lowercase: string): string {
		if (lowercase.includes('student') && lowercase.includes('advice')) {
			return 'student';
		} else if (lowercase.includes('gcse')) {
			//gcse or gcses
			return 'gcses';
		} else if (lowercase.includes('teacher')) {
			//teacher advice
			return 'teacher';
		} else if (lowercase.includes('work')) {
			return 'work-experience';
		} else if (lowercase.includes('ucas')) {
			return 'ucas';
			// some have category as accommodation_and_housing
		} else if (lowercase.includes('accommodation')) {
			return 'accommodation';
		} else {
			if (lowercase.includes('_')) {
				return lowercase.split('_').join('-');
			} else {
				return lowercase.split(' ').join('-');
			}
		}
	}

	transform(category: string, type: string): string {
		const lowercase = category.toLocaleLowerCase();

		if (type === 'advice') {
			return this.formatAdvice(lowercase);
		} else {
			return lowercase.split(' ').join('-');
		}
	}
}
