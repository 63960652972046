export const getRandomElementFromArray = <T>(array: Array<T>): T | null => {
	if (!array || !Array.isArray(array) || array.length === 0) return null;

	const randomIndex = Math.floor(Math.random() * array.length);
	return array[randomIndex];
};

export const isArrayEmpty = (array: any): boolean => {
	return !array || !Array.isArray(array) || array.length === 0;
};
