import { Input, Pipe, PipeTransform } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ProductEnum, ScreenWidth } from '@uc/web/shared/data-models';

@Pipe({
	name: 'abbrProduct',
	standalone: true,
})
export class AbbreviateProductPipe implements PipeTransform {
	@Input() observeBreakpoint = true;

	constructor(private _breakpointObserver: BreakpointObserver) {}

	transform(text: string): string {
		if (!text) return text;

		if (this._isDesktop()) {
			return text;
		}

		const words = text.split(' ');
		const abbreviatedWords = words
			.map((word, index) => {
				if (
					index === 0 &&
					(word.toLowerCase() === ProductEnum.Undergraduate ||
						word.toLowerCase() === ProductEnum.Postgraduate)
				) {
					return word.charAt(0) + 'G';
				} else {
					return word;
				}
			})
			.join(' ');

		return abbreviatedWords;
	}

	private _isDesktop() {
		return (
			this.observeBreakpoint &&
			!this._breakpointObserver.isMatched(`(max-width: ${ScreenWidth.SM - 1}px)`)
		);
	}
}
