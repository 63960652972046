import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class RegexService {
	strongPassword = new RegExp(
		'(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})',
	);
	mediumPassword = new RegExp(
		'^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})',
	);
	emailFormat = /^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,15}$/;
	mobileNumber = /^[\d+$]{10,}/;
	postcode =
		'^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$';
}
