import { BreakpointObserver } from '@angular/cdk/layout';
import {
	Directive,
	Input,
	OnDestroy,
	TemplateRef,
	ViewContainerRef,
} from '@angular/core';
import { ScreenWidth } from '@uc/web/shared/data-models';

type Condition = 'hideOnMobile' | 'hideOnDesktop';
enum ConditionEnum {
	hideOnMobile = 'hideOnMobile',
	hideOnDesktop = 'hideOnDesktop',
}
@Directive({
	standalone: true,
	selector: '[ucRemoveDuplicateDomElement]',
})
export class RemoveDuplicateDomElementDirective implements OnDestroy {
	@Input() set ucRemoveDuplicateDomElement(condition: Condition) {
		this._updateView(condition);
	}

	private _isMobile = false;
	private _condition: Condition = ConditionEnum.hideOnMobile;

	private _breakpointSub = this._breakpointObserver
		.observe(`(max-width: ${ScreenWidth.SM - 1}px)`)
		.subscribe((result) => {
			const isMobile = result.matches;

			if (this._isMobile !== isMobile) {
				this._isMobile = isMobile;
				this._updateView(this._condition);
			}
		});

	constructor(
		private _templateRef: TemplateRef<any>,
		private _viewContainer: ViewContainerRef,
		private _breakpointObserver: BreakpointObserver,
	) {}

	ngOnDestroy(): void {
		this._breakpointSub?.unsubscribe();
	}

	private _updateView(condition: Condition) {
		this._condition = condition;
		this._viewContainer.clear();

		if (condition === ConditionEnum.hideOnMobile && this._isMobile) {
			this._viewContainer.clear();
		} else if (condition === ConditionEnum.hideOnDesktop && !this._isMobile) {
			this._viewContainer.clear();
		} else {
			this._viewContainer.createEmbeddedView(this._templateRef);
		}
	}
}
