import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { OrdinalPipe } from './ordinal.pipe';

@Pipe({
	name: 'dateOrdinal',
	standalone: true,
})
export class DateOrdinalPipe implements PipeTransform {
	private _ordinalPipe = new OrdinalPipe();
	transform(value: string) {
		if (!value || isNaN(Date.parse(value))) return value;

		const day = parseInt(formatDate(value, 'd', 'en-UG'));
		const result = this._ordinalPipe.transform(day);

		return result;
	}
}
