import { Pipe, PipeTransform } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ScreenWidth } from '@uc/web/shared/data-models';

@Pipe({
	name: 'abbrButtonText',
	standalone: true,
})
export class AbbreviateButtonTextPipe implements PipeTransform {
	constructor(private _breakpointObserver: BreakpointObserver) {}

	transform(text: string, shortText: string): string {
		if (!text || !shortText) return '';
		return this._isMobile() ? shortText : text;
	}

	private _isMobile() {
		return this._breakpointObserver.isMatched(`(max-width: ${ScreenWidth.SM - 1}px)`);
	}
}
