export const isObject = (object: unknown): boolean => {
	if (typeof object === 'object' && object !== null) {
		return true;
	}
	return false;
};

/**
 * @returns true if object is empty
 */
export const isObjectEmpty = (object: any): boolean => {
	if (isObject(object)) {
		return Object.keys(object).length === 0;
	}
	return true;
};

/**
 * checks if the store has properties
 * and checks if the only property is @type added by default from the store
 * @returns true if the store has more than one property
 */
export const isStoreEmpty = (object: any): boolean => {
	if (isObject(object)) {
		const keys = Object.keys(object);
		if (isObjectEmpty(object) || (keys.length === 1 && keys[0] === 'type')) {
			return true;
		}
		return false;
	}
	return true;
};

export const startsWithVowel = (text: string): boolean => {
	const vowels = 'aeiou';
	return vowels.indexOf(text[0].toLowerCase()) !== -1;
};
