import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	standalone: true,
	selector: '[ucDateFormatter]',
})
export class DateFormatterDirective {
	flag: boolean = false;
	constructor(public ngControl: NgControl) {}

	/**
	 * @param event input value entered by user
	 * if this.flag is set to false, it will transform the input value
	 * and write it to the form control
	 * if this.flag is set to true, it will not transform the input value
	 */
	@HostListener('ngModelChange', ['$event']) onModelChange(event: any) {
		if (!this.flag) {
			let newVal = this.transform(event);
			this.ngControl.valueAccessor?.writeValue(newVal);
		}
	}

	transform(value: string) {
		let text: string = value;

		return (
			text
				// Day DD
				.replace(/^[4-9]/g, '0' + text) // Adding 0 to start when numbers 4-9 entered.
				.replace(/^3[2-9]/g, text[0] + '') //Not allowing DD > 31
				.replace(/^(0[1-9]|[1-2][0-9]|3[0-1])$/g, '$1/') // Add '/' after DD
				.replace(/^00/g, text.slice(0, -1))
				// Month MM
				.replace(/^\d{2}\/[2-9]/g, text.slice(0, 3) + '0' + text.slice(3, 4)) // Not allowing MM to start with num > 1
				.replace(/^\d{2}\/1[3-9]/g, text.slice(0, -1)) // Not allowing MM to end with num > 12
				.replace(/^\d{2}\/00/g, text.slice(0, -1)) // Not allowing MM to be 00
				.replace(/^(\d{2}\/\d{2})$/g, '$1/') // To put a '/' after MM/DD/
				// Exclusions
				.replace(/[a-z]/gi, '') // To not allow letters
				.replace(/(?!\/)[^a-z0-9]/gi, '') // Not allow special chars except '/'
				.replace(/^\//gi, '') // Not allow the string to start with '/'
		);
	}

	/**
	 * @param event KeyboardEvent including information about the key pressed
	 * if the key pressed is 'Backspace', it will set this.flag to true
	 * so that the input does not get transformed and the input field doesn't automatically get updated
	 */
	@HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
		if (event.key === 'Backspace') {
			this.flag = true;
		} else {
			this.flag = false;
		}
	}
}
