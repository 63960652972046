import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	standalone: true,
	name: 'highlightSearch',
})
export class HighlightSearchPipe implements PipeTransform {
	transform(value: string, search: string) {
		if (!search) {
			return value;
		} else {
			const regexString = '(' + search.split(' ').join('|') + ')';
			const regex = new RegExp(regexString, 'ig');
			return value.replace(regex, '<span class="highlight">' + '$&' + '</span>');
		}
	}
}
