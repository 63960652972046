import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	standalone: true,
	name: 'ucEntities',
})
export class EntitiesPipe implements PipeTransform {
	transform(text: string): string {
		if (!text) return text;

		return text
			.replace(/&rsquo;/g, "'")
			.replace(/andamp;/g, '&')
			.replace(/&#39;/g, "'")
			.replace(/&#039;/g, "'")
			.replace(/&lt;br&gt;/g, '<br/>')
			.replace(/&lt;p&gt;/g, '<p>')
			.replace(/&lt;\/p&gt;/g, '</p>')
			.replace(/&lt;a&gt;/g, '<a>')
			.replace(/&lt;\/a&gt;/g, '</a>')
			.replace(/\n/g, '<br/>')
			.replace(/&lt;/g, '')
			.replace(/&pound;/g, '£')
			.replace(/&ndash;/g, '–')
			.replace(/&mdash;/g, '—')
			.replace(/&amp;/g, '&')
			.replace(/&quot;/g, '"');
	}
}
