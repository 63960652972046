import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { Environment } from './environment.models';

@Injectable({
	providedIn: 'root',
})
export class EnvironmentApiService {
	PHP_URL!: string;
	API_URL!: string;
	JSON_URL!: string;

	private _local = 'http://127.0.0.1';

	private _platformId = inject(PLATFORM_ID);

	constructor(@Inject('environment') private _environment: Environment) {
		const apiUrl = this._environment.api_url;
		const phpDomain = this._environment.php_url;
		const port = this._environment.port ?? 'assets/json/';

		const isLocal = isPlatformServer(this._platformId) && !this._environment.devMode;

		this.API_URL = isLocal ? `${this._local}/api/` : apiUrl;
		this.PHP_URL = isLocal ? `${this._local}/php/Web/` : phpDomain;
		this.JSON_URL = isPlatformServer(this._platformId) ? port : 'assets/json/';
	}
}
