export enum Offset {
	Off = 0,
	On = 1,
}

export type CourseSearchQueryParamKey =
	| 'page'
	| 'ucaspts'
	| 'mode'
	| 'ctype'
	| 'loc'
	| 'utype'
	| 's'
	| 'uni';

export type CourseSearchPillUrlKey =
	| 'product'
	| 'ucaspts'
	| 'loc'
	| 'mode'
	| 'ctype'
	| 'utype';
