import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ScrollService {
	constructor(@Inject(DOCUMENT) private document: Document) {}

	scrollToTop(
		element: HTMLElement | null,
		scrollBehaviour: ScrollBehavior = 'smooth',
	): void {
		if (element) {
			element.scrollIntoView({
				block: 'start',
				behavior: scrollBehaviour,
			});
		}
	}

	scrollToError(): void {
		const error = this.document
			.getElementsByClassName('error-show')
			.item(0) as HTMLElement;
		if (error) {
			error.scrollIntoView({
				block: 'center',
				behavior: 'smooth',
			});
		}
	}
}
