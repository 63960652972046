import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class HelperService {
	splitUrlIntoParts(url: string, makeRelative: boolean = false): string[] {
		const urlParts = [];

		if (!makeRelative) {
			urlParts.push('/');
		}

		const splitUrl = url.split('/').filter((ele: string) => ele !== '');
		urlParts.push(...splitUrl);

		return urlParts;
	}

	isObject(obj: any): boolean {
		return obj != null && obj.constructor.name === 'Object';
	}

	isArray(array: any): boolean {
		return Array.isArray(array);
	}

	addRemovePrimitiveFromArray(element: any, array: any[]) {
		const copy = [...array];
		const indexOfUni = copy.indexOf(element);

		if (indexOfUni === -1) {
			copy.push(element);
		} else {
			copy.splice(indexOfUni, 1);
		}

		return copy;
	}

	/**
	 * @param numberOfYears how many years do you want to generate
	 * @param operator can be either descrease or increase
	 * @param startYear defaults to current year but pass any valid year in format YYYY
	 * @returns an array of integers representing years
	 */
	generateArrayOfYears(
		numberOfYears: number,
		operator: 'decrease' | 'increase',
		startYear = new Date().getFullYear(),
	): number[] {
		const years = [];

		for (let i = 0; i < numberOfYears; i++) {
			if (operator === 'decrease') {
				years.push(startYear - i);
			} else if (operator === 'increase') {
				years.push(startYear + i);
			}
		}
		return years;
	}

	filterForObject(array: any[], id: number) {
		const res = array.filter((ele) => ele.id === id);
		if (res.length > 0) {
			return res[0];
		}
	}

	//creates a deep copy of object
	// !NOTE: do not use for objects with a date
	deepCopyObject(object: any) {
		const json = JSON.stringify(object);
		const copy = JSON.parse(json);
		return copy;
	}
}
