export const isClearingKeywordsEnabled = () => {
	const date = new Date();
	const currentYear = date.getFullYear();

	const today = date.getTime();
	const start = new Date(currentYear, 6, 1).getTime();
	const end = new Date(currentYear, 8, 1).getTime();

	return today >= start && today < end;
};
