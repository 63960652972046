export enum ProductEnum {
	Undergraduate = 'undergraduate',
	Postgraduate = 'postgraduate',
	Clearing = 'clearing',
}

export enum UppercaseProductEnum {
	Undergraduate = 'UNDERGRADUATE',
	Postgraduate = 'POSTGRADUATE',
	Clearing = 'CLEARING',
}

export type Product = `${ProductEnum}`;
export type UppercaseProduct = `${UppercaseProductEnum}`;

export type ProductAbbr = 'ug' | 'pg' | 'cle';

export type ProductId = 1 | 2 | 3;

export type DegreeProduct = ProductEnum.Undergraduate | ProductEnum.Postgraduate;
