import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
	standalone: true,
	selector: '[ucToggleFaq]',
})
export class ToggleFaqDirective {
	constructor(
		private renderer: Renderer2,
		private el: ElementRef,
	) {}

	showHideElement() {
		const sibling = this.el.nativeElement.nextElementSibling;
		const svgs = this.el.nativeElement.getElementsByTagName('svg');
		//svgs[0] - arrow down, svgs[1] - arrow up

		if (sibling.classList.contains('sr-only')) {
			this.renderer.removeClass(sibling, 'sr-only');
			this.renderer.addClass(svgs[0], 'hidden');
			this.renderer.removeClass(svgs[1], 'hidden');
		} else {
			this.renderer.addClass(sibling, 'sr-only');
			this.renderer.removeClass(svgs[0], 'hidden');
			this.renderer.addClass(svgs[1], 'hidden');
		}
	}

	@HostListener('click') onClick() {
		this.showHideElement();
	}

	@HostListener('keydown', ['$event']) keyEvent(event: KeyboardEvent) {
		if (event.key === 'Enter') {
			this.showHideElement();
		}
	}
}
