import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	standalone: true,
	name: 'categoryName',
})
export class CategoryNamePipe implements PipeTransform {
	transform(name: string): string {
		if (!name) return name;

		const nameParts = name.includes('_') ? name.split('_') : name.split(' ');

		nameParts.forEach((part: string, index: number) => {
			if (
				!part.toLowerCase().includes('ucas') &&
				!part.toLowerCase().includes('gcse')
			) {
				nameParts[index] = part[0].toUpperCase() + part.slice(1);
			} else {
				nameParts[index] = part.toLowerCase().includes('ucas') ? 'UCAS' : 'GCSEs';
			}
		});

		return nameParts.join(' ');
	}
}
