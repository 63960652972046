import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class CanonicalService {
	constructor(@Inject(DOCUMENT) private document: Document) {}

	updateCanonicalUrl(url: string) {
		const head = this.document.getElementsByTagName('head')[0];
		let element: HTMLLinkElement | null =
			this.document.querySelector(`link[rel='canonical']`) || null;
		if (element === null) {
			element = this.document.createElement('link') as HTMLLinkElement;
			head.appendChild(element);
		}
		element.setAttribute('rel', 'canonical');
		element.setAttribute('href', url);
	}
}
