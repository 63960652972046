import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'uni',
	standalone: true,
})
export class UniPipe implements PipeTransform {
	/**
	 * Transforms 'University' to 'Uni'
	 */
	transform(value: string): string {
		if (!value) return value;
		return value.replace(/University/g, 'Uni');
	}
}
