export function isInAppBrowser(platform: string | null) {
	if (!platform) return false;

	return (
		platform?.toLocaleLowerCase() === 'ios' ||
		platform?.toLocaleLowerCase() === 'android'
	);
}

export function normalizePlatformName(platform: string | null): 'iOS' | 'Android' | null {
	if (!platform) return null;
	if (platform.toLocaleLowerCase() === 'ios') return 'iOS';
	else if (platform.toLocaleLowerCase() === 'android') return 'Android';
	else return null;
}
