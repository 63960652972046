export const getProspectusDeliveryTime = (): string => {
	const date = new Date();
	const time = date.getHours();
	if (time > 20) {
		return '1pm tomorrow';
	} else if (time < 13) {
		return '1pm';
	} else if (time >= 13 && time < 15) {
		return '3pm';
	} else if (time >= 15 && time < 18) {
		return '6pm';
	} else {
		return '9pm';
	}
};
