import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
	standalone: true,
	selector: '[ucDropdown]',
})
export class DropdownDirective {
	@Input() elementId = '';
	@HostBinding('class.open') isOpen = false;
	@HostListener('document:click', ['$event']) toggleOpen(event: Event) {
		this.isOpen = this.elRef.nativeElement.contains(event.target)
			? !this.isOpen
			: false;
	}
	constructor(private elRef: ElementRef) {}
}
