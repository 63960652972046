export enum RecaptchaAction {
	ORDER_PROSPECTUS = 'order_prospectus',
	REQUEST_INFO = 'request_info',
	REGISTER_USER = 'register_user',
	REGISTER_QUIZ_USER = 'quick_register_user',
	REGISTER_INTEREST = 'register_interest',
	RESET_PASSWORD = 'password_reset',
	REQUEST_PASSWORD_RESET = 'password_request',
	LOGIN = 'login_user',
}

export type RecaptchaActionType = `${RecaptchaAction}`;
