import { Injectable } from '@angular/core';

import { CustomChoiceOptions, CustomSelectOptions } from '@uc/web/shared/data-models';
import { HelperService } from './helper.service';
import { ClearingService } from './clearing.service';

@Injectable({
	providedIn: 'root',
})
export class FormOptionsService {
	constructor(
		private _helper: HelperService,
		private _clearingSrv: ClearingService,
	) {}

	//static
	getDegreeOptions(): string[] {
		return ['Bachelors', 'Masters', 'PHD', 'HND', 'HNC'];
	}

	getDegreeOptionsConfig(
		shortTitle = 'I want to pursue a:',
		control = 'degree',
	): CustomChoiceOptions {
		return {
			options: this.getDegreeOptions(),
			shortTitle,
			formControlName: control,
			controlName: control,
		};
	}

	getStartYearConfig(
		longTitle = 'What year are you planning to start University?',
		shortTitle = 'When would you like to start university?',
		control = 'startYear',
	): CustomChoiceOptions {
		return {
			options: this._helper.generateArrayOfYears(
				5,
				'increase',
				this._clearingSrv.getYear(),
			),
			longTitle,
			shortTitle,
			formControlName: control,
			controlName: control,
		};
	}

	getSubjectsConfig(
		shortTitle = 'What are you interested in studying at University?',
		control = 'subject',
	): CustomSelectOptions {
		return {
			options: [],
			key: '',
			formControlName: control,
			controlName: control,
			shortTitle,
			isArrayOfObjects: false,
		};
	}

	reverseDate(date: string) {
		if (!date) return;

		if (date.split('-')[0].length === 4 && date.includes('-')) {
			return date.split('-').reverse().join('/');
		} else if (date.includes('-')) {
			return date.split('-').join('/');
		} else if (date.split('/')[0].length === 4) {
			return date.split('/').reverse().join('/');
		} else {
			return date;
		}
	}

	checkIfSubjectPresent(subject: string | null | undefined) {
		if (!subject) return false;

		if (subject.toLowerCase() === 'unknown') return false;

		return true;
	}
}
