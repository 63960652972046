import {
	BackendErrorData,
	ErrorFormFields,
	RegisterResponseError,
} from '@uc/web/shared/data-models';

const errorFormFields: ErrorFormFields = {
	first_name: ['firstname', 'required'],
	last_name: ['lastname', 'required'],
	email: ['email', 'required'],
	phone_number: ['phoneDetailsForm.phone', 'phoneInvalid'],
	password: ['password', 'invalid'],
	'address.postcode': [['postcode', 'postcodeData'], 'postcodeInvalid'],
	country_code: ['phoneDetailsForm.countryCode', 'required'],
	start_year: ['startYear', 'required'],
	'preferences.start_year': ['startYear', 'required'],
	'preferences.qualification': ['degree', 'required'],
	'preferences.subject': ['subject', 'required'],
};

export const getErrorMessage = (error: RegisterResponseError): string => {
	if ('message' in error.error) {
		return 'Account creation failed: Access denied.';
	}
	if ('errors' in error.error) {
		const errorKey = Object.keys(error.error.errors)[0];
		const errorMessage = error.error.errors[errorKey][0];
		return errorMessage;
	}
	return 'An unknown error occurred. Please try again.';
};

export const getErrorKey = (error: RegisterResponseError) => {
	if ('errors' in error.error) {
		const errorKey = Object.keys(error.error.errors)[0];
		return errorKey;
	}
	return '';
};

export const mapErrorToFormControl = (
	error: string,
	errorKey: string,
): BackendErrorData | null => {
	if (error?.includes('Email is already in use')) {
		return {
			controlName: 'email',
			errorObject: {
				emailTaken: true,
			},
		};
	} else if (error?.includes('The email must be a valid email address')) {
		return {
			controlName: 'email',
			errorObject: {
				emailInvalid: true,
			},
		};
	} else if (errorFormFields[errorKey]) {
		const key = [errorFormFields[errorKey][1]] as unknown as string;
		return {
			controlName: errorFormFields[errorKey][0],
			errorObject: {
				[key]: true,
				errorMessage: error,
			},
		};
	}

	return null;
};
