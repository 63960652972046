import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT, Location } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class UrlService {
	constructor(
		@Inject(DOCUMENT) private _document: Document,
		private _router: Router,
		private _location: Location,
	) {}

	changeUrlWithoutReload(basePath: string, key: string, value: string | null = null) {
		if (!value) {
			this._location.go(basePath);
		} else {
			this._location.go(`${basePath}?${key}=${value}`);
		}
	}

	redirectBasedOnHistory() {
		const sameSite = this._document.referrer.indexOf(location.host) !== -1;

		if (sameSite) {
			this._location.back();
		} else {
			this._router.navigate(['']);
		}
	}

	historyBack(position: number = -1) {
		const state: any = this._location.getState();

		if (state?.navigationId >= Math.abs(position)) {
			this._location.historyGo(position);
		} else {
			this._router.navigate(['']);
		}
	}
}
